import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UpdateProfile from 'views/profile/UpdateProfile';
import UpdateSecurity from 'views/profile/UpdateSecurity';
import RoleGuard from 'utils/route-guard/RoleGuard';
import Error from 'views/pages/maintenance/Error';

import WeeklyResultForm from 'views/weekly-result/WeeklyResultForm';
import WeeklyResultList from 'views/weekly-result/WeeklyResultList';
import Lazy from 'yup/lib/Lazy';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// Profile Routing
const AppProfile = Loadable(lazy(() => import('views/profile/Profile')));

// Clients Routing
const AppClientList = Loadable(lazy(() => import('views/client/ClientList')));
const AppUpdateClient = Loadable(lazy(() => import('views/client/UpdateClient')));
const AppClientDetail = Loadable(lazy(() => import('views/client/ClientDetail')));
const AppInvoiceList = Loadable(lazy(() => import('views/client/invoice/InvoiceList')));
const AppUpdateInvoice = Loadable(lazy(() => import('views/client/invoice/UpdateInvoice')));
const AppQuotationList = Loadable(lazy(() => import('views/client/quotation/QuotationList')));
const AppUpdateQuotation = Loadable(lazy(() => import('views/client/quotation/UpdateQuotation')));
const AppQuotationLeadList = Loadable(lazy(() => import('views/quotations/quotation/QuotationList')));
const AppUpdateQuotationLead = Loadable(lazy(() => import('views/quotations/quotation/UpdateQuotation')));
const AppClientProfileEdit = Loadable(lazy(() => import('views/client/UpdateClientProfile')));
const AppClientConnectionEdit = Loadable(lazy(() => import('views/client/UpdateClientConnection')));

// Sample REquest Routing
const AppSampleRequestList = Loadable(lazy(() => import('views/sample-request/SampleRequestList')));
const AppAddSampleRequestList = Loadable(lazy(() => import('views/sample-request/UpdateSampleLead')));
const AppSampleDetail = Loadable(lazy(() => import('views/sample-request/SampleDetail')));
const AppSampleProfile = Loadable(lazy(() => import('views/sample-request/SampleProfile')));
const AppSampleAssignEdit = Loadable(lazy(() => import('views/sample-request/assign/AssignSample')));
const AppSampleHistory = Loadable(lazy(() => import('views/sample-request/SampleHistoryTab')));
// const AppSampleOrdersList = Loadable(lazy(() => import('views/customer-request/orders/OrdersList')));
// social Lead Routing
const AppSocialLeadList = Loadable(lazy(() => import('views/social-lead/socialLeadList')));
const AppAddSocialLead = Loadable(lazy(() => import('views/social-lead/UpdateSocialLead')));
const AppSocialLeadDetail = Loadable(lazy(() => import('views/social-lead/LeadDetail')));
const AppSocialLeadProfile = Loadable(lazy(() => import('views/social-lead/LeadProfile')));
const AppSampleLeadHistory = Loadable(lazy(() => import('views/social-lead/LeadHistoryTab')));
const AppSocialAssignEdit = Loadable(lazy(() => import('views/social-lead/assign/AssignLead')));
const AppSocialQuotationList = Loadable(lazy(() => import('views/social-lead/quotation/QuotationList')));
const AppUpdateSocialQuotation = Loadable(lazy(() => import('views/social-lead/quotation/UpdateQuotation')));
const AppSocialLeadAssignEdit = Loadable(lazy(() => import('views/social-lead/assign/AssignLead')));
const AppContactsComments = Loadable(lazy(() => import('views/social-lead/follow-ups comments/ContactsComments')));
const AppUpdateContactsComments = Loadable(lazy(() => import('views/social-lead/follow-ups comments/UpdateFollowupsComments')));
const AppContactFolowupDetail = Loadable(lazy(() => import('views/social-lead/follow-ups comments/ContactFollowupsDetails')));
const AppContactFolowupProfile = Loadable(lazy(() => import('views/social-lead/follow-ups comments/ContactCommentProfile')));
const AppFellingthermometer = Loadable(lazy(() => import('views/social-lead/thermometer/FellingThermometer')));
const AppMomenutumthermometer = Loadable(lazy(() => import('views/social-lead/thermometer/momentunThermometer')));
const AppWorththermometer = Loadable(lazy(() => import('views/social-lead/thermometer/WorthThermometer')));

// Registerd customer routing
// const AppCustomerRequestList = Loadable(lazy(() => import('views/customer-request/CustomerRequestList')));
// const AppAddCustomerRequest = Loadable(lazy(() => import('views/customer-request/UpdateCustomerRequest')));
// // const AddCustomerBasicDetail = Loadable(lazy(() => import('views/customer-request/CustomerBasicDetail')));
// // const AddCustomerBankDetail = Loadable(lazy(() => import('views/customer-request/CustomerBankDetails')));
// const AppCustomerDetail = Loadable(lazy(() => import('views/customer-request/CustomerDetail')));
// const AppCustomerProfile = Loadable(lazy(() => import('views/customer-request/CustomerProfile')));
// // const CustomerBankInfo = Loadable(lazy(() => import('views/customer-request/CustomerBankInfo')));
// // const AppCustomerBankHistory = Loadable(lazy(() => import('views/customer-request/CustomerBankDetails')));
const AppQuotationRequestList = Loadable(lazy(() => import('views/customer-request/quotation/QuotationRequestList')));
const AppSampleList = Loadable(lazy(() => import('views/customer-request/sample/SampleRequestList')));
// const AppOrdersList = Loadable(lazy(() => import('views/customer-request/orders/OrdersList')));
const AppCustomerRequestList = Loadable(lazy(() => import('views/customer-request/CustomerRequestList')));
const AppAddCustomerList = Loadable(lazy(() => import('views/customer-request/UpdateCustomerRequest')));
const AppCustomerDetail = Loadable(lazy(() => import('views/customer-request/CustomerDetail')));
const AppCustomerProfile = Loadable(lazy(() => import('views/customer-request/CustomerProfile')));
const AppBankDetails = Loadable(lazy(() => import('views/customer-request/bankDetails')));
const AppCustomerAssign = Loadable(lazy(() => import('views/customer-request/assign/AssignSocialLead')));
const AppHistoryDetails = Loadable(lazy(() => import('views/customer-request/CustomerHistoryTab')));

// Quotations Routing
const AppLeadList = Loadable(lazy(() => import('views/quotations/QuotationList')));
const AppUpdateLead = Loadable(lazy(() => import('views/quotations/UpdateQuotation')));
// const AppUpdateLead = Loadable(lazy(() => import('views/quotations/UpdateLead')));
const AppLeadDetail = Loadable(lazy(() => import('views/quotations/QuotationDetail')));
const AppLeadProfile = Loadable(lazy(() => import('views/quotations/QuotationProfile')));
const AppLeadProfileEdit = Loadable(lazy(() => import('views/quotations/UpdateQuotationProfile')));
const AppLeadAssignEdit = Loadable(lazy(() => import('views/quotations/assign/AssignQuotation')));
const AppLeadHistory = Loadable(lazy(() => import('views/quotations/QuotationHistoryTab')));

// Sample List
// const AppOrderList = Loadable(lazy(() => import('views/orders/OrderList')));
// const AppAddOrdersList = Loadable(lazy(() => import('views/orders/UpdateOrders')));
// Staff Routing
const AppStaffList = Loadable(lazy(() => import('views/staff/StaffList')));
const AppUpdateStaff = Loadable(lazy(() => import('views/staff/UpdateStaff')));
const AppStaffDetails = Loadable(lazy(() => import('views/staff/StaffDetails')));
const AppStaffProfile = Loadable(lazy(() => import('views/staff/StaffProfile')));
const AppStaffAttendance = Loadable(lazy(() => import('views/staff/StaffAttendance')));
const AppStaffLeave = Loadable(lazy(() => import('views/staff/StaffLeave')));
const AppStaffApplyLeave = Loadable(lazy(() => import('views/staff/ApplyLeave')));
const AppStaffAccountDetails = Loadable(lazy(() => import('views/staff/account/AccountDetails')));
const AppStaffApplyAccountDetails = Loadable(lazy(() => import('views/staff/account/UpdateAccount')));
const AppStaffSecurity = Loadable(lazy(() => import('views/staff/UpdateSecurity')));
const AppUpdateStaffDetails = Loadable(lazy(() => import('views/staff/EditStaffDetail')));
const AppstabsProfile = Loadable(lazy(() => import('views/staff/staffTabsProfile')));
const AppStaffWarehouseDetails = Loadable(lazy(() => import('views/staff/warehouse/WarehouseDetails')));
const AppStaffUpdateWarehouse = Loadable(lazy(() => import('views/staff/warehouse/UpdateWarehouse')));

// staff-follow-up
const AppAstronautsComments = Loadable(lazy(() => import('views/staff/astronuats follow-up/AstronautsComments')));
const AppUpdateAstronautsComments = Loadable(lazy(() => import('views/staff/astronuats follow-up/UpdateFollowupsAstronauts')));
const AppAstronautsFolowupDetail = Loadable(lazy(() => import('views/staff/astronuats follow-up/AstronautsFollowupsDetails')));
const AppAstronautsFolowupProfile = Loadable(lazy(() => import('views/staff/astronuats follow-up/AstronautsFollowupProfile')));

// =============================== //
// Tickets Routing
const AppTickets = Loadable(lazy(() => import('views/ticket/SupportTickets')));

// Reports Routing
const AppReports = Loadable(lazy(() => import('views/reports/Reports')));

// =========== || HRM SETTING || ================= //

// Attendance Routing
const AppAttendanceList = Loadable(lazy(() => import('views/hrm/attendance/AttendanceList')));

// Leaves Routing
const AppLeaveList = Loadable(lazy(() => import('views/hrm/leave/LeaveList')));
const AppUpdateLeave = Loadable(lazy(() => import('views/hrm/leave/UpdateLeave')));

// Announcement Routing
const AppAnnouncementList = Loadable(lazy(() => import('views/hrm/announcement/AnnouncementList')));
const AppUpdateAnnouncement = Loadable(lazy(() => import('views/hrm/announcement/UpdateAnnouncement')));

// ========= || SETTING ROUTING || ===================== //
// Department Routing
const AppDepartmentList = Loadable(lazy(() => import('views/settings/department/DepartmentList')));
const AppUpdateDepartment = Loadable(lazy(() => import('views/settings/department/UpdateDepartment')));

// Documents Routing
const AppDocumentsTypesList = Loadable(lazy(() => import('views/settings/documents/DocumentsTypesList')));
const AppUpdateDocumentsTypes = Loadable(lazy(() => import('views/settings/documents/UpdateDocumentsTypes')));

// Role Routing
const AppRoleList = Loadable(lazy(() => import('views/settings/role/RoleList')));
const AppUpdateRole = Loadable(lazy(() => import('views/settings/role/UpdateRole')));

// Countries  Routing
const AppCountriesList = Loadable(lazy(() => import('views/settings/places/countries/CountryName')));
const AppUpdateCountries = Loadable(lazy(() => import('views/settings/places/countries/UpdateCountryName')));

// States Routing
const AppStatesList = Loadable(lazy(() => import('views/settings/places/states/StateName')));
const AppUpdateStates = Loadable(lazy(() => import('views/settings/places/states/UpdateStateName')));

// Cities Routing
const AppCitiesList = Loadable(lazy(() => import('views/settings/places/cities/CityName')));
const AppUpdateCities = Loadable(lazy(() => import('views/settings/places/cities/UpdateCityName')));

// Shift Routing
const AppShiftList = Loadable(lazy(() => import('views/settings/shift/ShiftList')));
const AppUpdateShift = Loadable(lazy(() => import('views/settings/shift/UpdateShift')));

// Leave Type Routing
const AppLeaveType = Loadable(lazy(() => import('views/settings/leave-type/LeaveTypeList')));
const AppUpdateLeaveType = Loadable(lazy(() => import('views/settings/leave-type/UpdateLeaveType')));

// Holidays Routing
const AppHolidayList = Loadable(lazy(() => import('views/settings/holiday/HolidayList')));
const AppUpdateHoliday = Loadable(lazy(() => import('views/settings/holiday/UpdateHoliday')));

// Status Routing
const AppStatusList = Loadable(lazy(() => import('views/settings/status/StatusList')));
const AppUpdateStatus = Loadable(lazy(() => import('views/settings/status/UpdateStatus')));

// front-setting Routing
const AppUpdateFrontSetting = Loadable(lazy(() => import('views/settings/front-setting/UpdateFrontSetting')));

// testimonial Routing
const AppTestimonialList = Loadable(lazy(() => import('views/settings/testimonial/TestimonialList')));
const AppUpdateTestimonial = Loadable(lazy(() => import('views/settings/testimonial/UpdateTestimonial')));

// items routing
const AppItemList = Loadable(lazy(() => import('views/items/itemList')));
const AppUpdateItem = Loadable(lazy(() => import('views/items/updateItems')));
const AppCartonsDetail = Loadable(lazy(() => import('views/items/ItemsDetail')));
const AppCartonsProfile = Loadable(lazy(() => import('views/items/ItemsProfile')));
const AppInkDetails = Loadable(lazy(() => import('views/items/InkDetails')));
const AppCartonsAssign = Loadable(lazy(() => import('views/items/assign/AssignSocialLead')));
const AppCartonsHistory = Loadable(lazy(() => import('views/items/CartonsHistoryTab')));

// careers routing
const AppCareersList = Loadable(lazy(() => import('views/careers/careersList')));
const AppUpdateCareersList = Loadable(lazy(() => import('views/careers/UpdateCareer')));
const AppCareersDetail = Loadable(lazy(() => import('views/careers/CareersDetail')));
const AppCareersProfile = Loadable(lazy(() => import('views/careers/CareersProfile')));
const AppCareersEducation = Loadable(lazy(() => import('views/careers/CareersEducation')));

// position routing
const AppPositionList = Loadable(lazy(() => import('views/settings/position/positionList')));
const AppUpdatePosition = Loadable(lazy(() => import('views/settings/position/UpdatePosition')));

// Business routing
const AppBusinessList = Loadable(lazy(() => import('views/contact-managment/business/BusinessList')));
const AppUpdateBusiness = Loadable(lazy(() => import('views/contact-managment/business/UpdateBusiness')));

// Follow-up routing for contacts controls
const AppFollowUpList = Loadable(lazy(() => import('views/contact-managment/follow-up astronaut/FollowUpAstronautList')));
const AppUpdateFollowUp = Loadable(lazy(() => import('views/contact-managment/follow-up astronaut/UpdateFollowUpAstronaut')));

// Follow-up  supervisor routing for contacts controls
const AppFollowUpSupervisorList = Loadable(lazy(() => import('views/contact-managment/follow-up supervisor/FollowUpSupervisorList')));
const AppUpdateFollowUpSupervisor = Loadable(lazy(() => import('views/contact-managment/follow-up supervisor/UpdateFollowUpSupervisor')));

// progress astronauts routing for contacts controls
const AppProgressAstronautsList = Loadable(lazy(() => import('views/contact-managment/progress astronaut/progressAstronautList')));
const AppUpdateProgressAstronauts = Loadable(lazy(() => import('views/contact-managment/progress astronaut/UpdateprogressAstronaut')));

// status supervisor routing for contacts controls
const AppStatusSupervisorList = Loadable(lazy(() => import('views/contact-managment/status supervisor/statusSupervisorList')));
const AppUpdateStatusSupervisor = Loadable(lazy(() => import('views/contact-managment/status supervisor/UpdateSupervisorList')));

// channel routing
const AppChannelList = Loadable(lazy(() => import('views/contact-managment/channel/ChannelList')));
const AppUpdateChannel = Loadable(lazy(() => import('views/contact-managment/channel/UpdateChannel')));

// styles routing
const AppStyleList = Loadable(lazy(() => import('views/items-managment/style/StyleList')));
const AppUpdatestyle = Loadable(lazy(() => import('views/items-managment/style/UpdateStyle')));

// Endurance routing
const AppEnduranceList = Loadable(lazy(() => import('views/items-managment/endurance/EnduranceList')));
const AppUpdateEndurance = Loadable(lazy(() => import('views/items-managment/endurance/UpdateEndurance')));

// Glued routing
const AppGluedList = Loadable(lazy(() => import('views/items-managment/glued/GluedList')));
const AppUpdateGlued = Loadable(lazy(() => import('views/items-managment/glued/UpdateGlued')));

// REcord routing
const AppRecordList = Loadable(lazy(() => import('views/items-managment/record/RecordList')));
const AppUpdateRecord = Loadable(lazy(() => import('views/items-managment/record/UpdateRecord')));

// ink-type routing
const AppInktypeList = Loadable(lazy(() => import('views/items-managment/ink-type/InktypeList')));
const AppUpdateInktype = Loadable(lazy(() => import('views/items-managment/ink-type/UpdateInktype')));

// straplling routing
const AppStraplingList = Loadable(lazy(() => import('views/items-managment/straping-direction/StrapingList')));
const AppUpdateStrapling = Loadable(lazy(() => import('views/items-managment/straping-direction/UpdateStraping')));

// boxes/bound routing
const AppBoxesList = Loadable(lazy(() => import('views/items-managment/boxes/BoxesList')));
const AppUpdateBoxes = Loadable(lazy(() => import('views/items-managment/boxes/UpdateBoxes')));

// inks routing
const AppInksList = Loadable(lazy(() => import('views/items-managment/inks/InksList')));
const AppUpdateInks = Loadable(lazy(() => import('views/items-managment/inks/UpdateInks')));

// machines routing
const AppMachinesList = Loadable(lazy(() => import('views/machines/MachineList')));
const AppUpdateMachines = Loadable(lazy(() => import('views/machines/UpdateMachine')));
const AppMachinesDetails = Loadable(lazy(() => import('views/machines/machinesDetail')));
const AppMachineProfile = Loadable(lazy(() => import('views/machines/MachinesProfile')));
const AppMachineAssign = Loadable(lazy(() => import('views/machines/assign/AssignSocialLead')));
const AppMachineHistory = Loadable(lazy(() => import('views/machines/MachinesHistoryTab')));

// spare-parts routing
const AppSparePartList = Loadable(lazy(() => import('views/spare-part/SparePartList')));
const AppUpdateSparePart = Loadable(lazy(() => import('views/spare-part/UpdateSparePart')));
const AppSparePartsDetails = Loadable(lazy(() => import('views/spare-part/sparePartsDetail')));
const AppSparePartsProfile = Loadable(lazy(() => import('views/spare-part/SparePartsProfile')));
const AppSparePartsAssign = Loadable(lazy(() => import('views/spare-part/assign/AssignSocialLead')));
const AppSparePartsHistory = Loadable(lazy(() => import('views/spare-part/SpareHistoryTab')));

// the-radar routes
const AppRadarList = Loadable(lazy(() => import('views/the-radar/the-radarList')));

// contacts follow-ups  routing

const AppContactsFollowUpsList = Loadable(lazy(() => import('views/contact-tracking/contactFollowUPList')));

// astronauts follow-ups  routing
const AppAstronautsFollowUpsList = Loadable(lazy(() => import('views/astronauts-tracking/astronautsFollowUPList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            state: { permissionID: 'All' },
            element: (
                <AuthGuard>
                    <RoleGuard userPermission={['Admin', 'Client', 'Staff']}>
                        <Dashboard />
                    </RoleGuard>
                </AuthGuard>
            )
        },
        {
            path: '/dashboard',
            state: { permissionID: 'All' },
            element: (
                <RoleGuard userPermission={['Admin', 'Client', 'Staff']}>
                    <Dashboard />
                </RoleGuard>
            )
        },
        {
            path: '/clients',
            element: (
                <RoleGuard permissionID="Client-read" userPermission={['Admin']}>
                    <AppClientList />
                </RoleGuard>
            )
        },
        {
            path: '/clients/add',
            element: (
                <RoleGuard permissionID="Client-write" userPermission={['Admin']}>
                    <AppUpdateClient pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/clients/:clientID/edit',
            element: (
                <RoleGuard permissionID="Client-write" userPermission={['Admin']}>
                    <AppUpdateClient pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/clients/:clientID',
            element: (
                <RoleGuard permissionID="Client-read" userPermission={['Admin']}>
                    <AppClientDetail title="Client Details" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '',
                    element: (
                        <RoleGuard permissionID="Quotation-read" userPermission={['Admin', 'Client']}>
                            <AppQuotationList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/quotation',
                    element: (
                        <RoleGuard permissionID="Quotation-read" userPermission={['Admin', 'Client']}>
                            <AppQuotationList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/quotation/add',
                    element: (
                        <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                            <AppUpdateQuotation pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/quotation/:quotationID',
                    element: (
                        <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                            <AppUpdateQuotation pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                // {
                //     path: '/clients/:clientID/quotation/:quotationID/edit',
                //     element: (
                //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                //             <AppUpdateQuotation pageBehaviour="Edit" />
                //         </RoleGuard>
                //     )
                // },
                {
                    path: '/clients/:clientID/invoice',
                    element: (
                        <RoleGuard permissionID="Invoice-read" userPermission={['Admin', 'Client']}>
                            <AppInvoiceList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/invoice/add',
                    element: (
                        <RoleGuard permissionID="invoice-write" userPermission={['Admin', 'Client']}>
                            <AppUpdateInvoice pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/invoice/:invoiceID',
                    element: (
                        <RoleGuard permissionID="invoice-write" userPermission={['Admin', 'Client']}>
                            <AppUpdateInvoice pageBehaviour="View" />
                        </RoleGuard>
                    )
                }
                // {
                //     path: '/clients/:clientID/invoice/:invoiceID/edit',
                //     element: (
                //         <RoleGuard permissionID="invoice-write" userPermission={['Admin', 'Client']}>
                //             <AppUpdateInvoice pageBehaviour="Edit" />
                //         </RoleGuard>
                //     )
                // }
            ]
        },
        {
            path: '/samples',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppSampleRequestList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/samples/add',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppAddSampleRequestList pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/samples/:sampleRequestID/edit',
            element: (
                <RoleGuard permissionID="Requestest-write" userPermission={['Admin']}>
                    <AppAddSampleRequestList pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/samples/:sampleRequestID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppSampleDetail title="Sample request" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/samples/:sampleRequestID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSampleProfile title="Sample request" pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/samples/:sampleRequestID/sample-assign',
                    // path: '/samples/:sampleRequestID',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSampleAssignEdit pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/samples/:sampleRequestID/sample-history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSampleHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
                // {
                //     path: 'orders',
                //     element: (
                //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                //             <AppSampleOrdersList pageBehaviour="view" />
                //         </RoleGuard>
                //     )
                // }
            ]
        },
        // {
        //     path: '/orders',
        //     element: (
        //         <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
        //             <AppOrderList pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/orders/add',
        //     element: (
        //         <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
        //             <AppAddOrdersList pageBehaviour="Add" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/orders/:sampleRequestID/edit',
        //     element: (
        //         <RoleGuard permissionID="Requestest-write" userPermission={['Admin']}>
        //             <AppAddOrdersList pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },
        // ==============================contacts follow up=====================
        {
            path: '/contact-tracking',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppContactsFollowUpsList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        // ========================== astronauts tracking ==================================
        {
            path: '/astronauts-tracking',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppAstronautsFollowUpsList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        // {
        //     path: '/contacts/:socialLeadID/follow-up/:followUpID/edit',
        //     element: (
        //         <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
        //             <AppUpdateContactsComments pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/contacts-followups/:followUpID',
        //     element: (
        //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
        //             <AppContactFolowupDetail title="Lead" pageBehaviour="View" />
        //         </RoleGuard>
        //     ),
        //     children: [
        //         {
        //             path: '/contacts-followups/:followUpID',
        //             element: (
        //                 <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
        //                     <AppContactFolowupProfile pageBehaviour="View" />
        //                 </RoleGuard>
        //             )
        //         }
        //     ]
        // },

        // ====================================================
        {
            path: '/contacts',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppSocialLeadList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contacts/add',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppAddSocialLead pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contacts/:socialLeadID/edit',
            element: (
                <RoleGuard permissionID="Requestest-write" userPermission={['Admin', 'Staff']}>
                    <AppAddSocialLead pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/contacts/:socialLeadID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppSocialLeadDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/contacts/:socialLeadID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSocialLeadProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/assign',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSocialAssignEdit pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSampleLeadHistory pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/follow-up',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppContactsComments pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/follow-up/add',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateContactsComments pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/follow-up/:followUpID/edit',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateContactsComments pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/follow-up/:followUpID',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppContactFolowupDetail pageBehaviour="View" />
                        </RoleGuard>
                    ),
                    children: [
                        {
                            path: '/contacts/:socialLeadID/follow-up/:followUpID',
                            element: (
                                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                                    <AppContactFolowupProfile pageBehaviour="View" />
                                </RoleGuard>
                            )
                        }
                    ]
                },

                {
                    path: '/contacts/:socialLeadID/thermometer',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppFellingthermometer pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/momentum-thermometer',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppMomenutumthermometer pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/contacts/:socialLeadID/worth-thermometer',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppWorththermometer pageBehaviour="Add" />
                        </RoleGuard>
                    )
                }
            ]
        },
        // =========================================================================================

        {
            path: '/customers',
            element: (
                <RoleGuard permissionID="CustomerRequest-read" userPermission={['Admin', 'Staff']}>
                    <AppCustomerRequestList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/add',
            element: (
                <RoleGuard permissionID="CustomerRequest-write" userPermission={['Admin', 'Staff']}>
                    <AppAddCustomerList pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID/edit',
            element: (
                <RoleGuard permissionID="Requestest-write" userPermission={['Admin']}>
                    <AppAddCustomerList pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppCustomerDetail title="Sample request" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/customers/:customerRequestID/assign',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCustomerAssign title="customer request" pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/customers/:customerRequestID/history',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppHistoryDetails title="customer request" pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/customers/:customerRequestID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCustomerProfile title="Sample request" pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'quotation-request',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppQuotationRequestList pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'sample-request',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSampleList pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'bank-details',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppBankDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/quotes',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppLeadList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/quotes/add',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLead pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/quotes/:leadID/edit',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLead pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/quotes/:leadID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppLeadDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/quotes/:leadID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppLeadProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                // {
                //     path: '/quotes/:leadID/profile',
                //     element: (
                //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                //             <AppLeadProfile pageBehaviour="View" />
                //         </RoleGuard>
                //     )
                // },
                // {
                //     path: '/quotes/:leadID/profile-edit',
                //     element: (
                //         <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                //             <AppLeadProfileEdit pageBehaviour="Edit" />
                //         </RoleGuard>
                //     )
                // },
                {
                    path: '/quotes/:leadID/quotes-assign',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppLeadAssignEdit pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/quotes/:leadID/quotes-history',
                    element: (
                        <RoleGuard permissionID="Lead-history-read" userPermission={['Admin', 'Staff']}>
                            <AppLeadHistory pageBehaviour="View" />
                        </RoleGuard>
                    )
                }
                // {
                //     path: '',
                //     element: (
                //         <RoleGuard permissionID="Quotation-read" userPermission={['Admin']}>
                //             <AppQuotationLeadList pageBehaviour="View" />
                //         </RoleGuard>
                //     )
                // },
                // {
                //     path: '/quotes/:leadID/quotation',
                //     element: (
                //         <RoleGuard permissionID="Quotation-read" userPermission={['Admin']}>
                //             <AppQuotationLeadList pageBehaviour="View" />
                //         </RoleGuard>
                //     )
                // },
                // {
                //     path: '/quotes/:leadID/quotation/add',
                //     element: (
                //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin']}>
                //             <AppUpdateQuotationLead pageBehaviour="Add" />
                //         </RoleGuard>
                //     )
                // },
                // {
                //     path: '/quotes/:leadID/quotation/:quotationID',
                //     element: (
                //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin']}>
                //             <AppUpdateQuotationLead pageBehaviour="View" />
                //         </RoleGuard>
                //     )
                // }
                // {
                //     path: '/leads/:leadID/quotation/:quotationID/edit',
                //     element: (
                //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                //             <AppUpdateQuotationLead pageBehaviour="Edit" />
                //         </RoleGuard>
                //     )
                // }
            ]
        },
        {
            path: '/machines',
            element: (
                <RoleGuard permissionID="machines-read" userPermission={['Admin', 'Staff']}>
                    <AppMachinesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/machine/add',
            element: (
                <RoleGuard permissionID="machines-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateMachines pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/machine/:machineID/edit',
            element: (
                <RoleGuard permissionID="spare-parts-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateMachines pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/machine/:machineID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppMachinesDetails title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/machine/:machineID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppMachineProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/machine/:machineID/profile',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppMachineProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/machine/:machineID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppMachineProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/machine/:machineID/assign',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppMachineAssign pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/machine/:machineID/history',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppMachineHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
            ]
        },
        // ---------------------------------------------------------------------------------------------------
        {
            path: '/spareparts',
            element: (
                <RoleGuard permissionID="spare-parts-read" userPermission={['Admin', 'Staff']}>
                    <AppSparePartList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/spareparts/add',
            element: (
                <RoleGuard permissionID="spare-parts-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateSparePart pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/spareparts/:sparePartID/edit',
            element: (
                <RoleGuard permissionID="spare-parts-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateSparePart pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/spareparts/:sparePartID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppSparePartsDetails title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/spareparts/:sparePartID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSparePartsProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/spareparts/:sparePartID/profile',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSparePartsProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/spareparts/:sparePartID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppSparePartsProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/spareparts/:sparePartID/assign',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppSparePartsAssign pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/spareparts/:sparePartID/history',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppSparePartsHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
            ]
        },
        // ------------------------------------------------------------------------
        {
            path: '/the-radar',
            element: (
                <RoleGuard permissionID="spare-parts-read" userPermission={['Admin', 'Staff']}>
                    <AppRadarList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        // ----------------------------------------------------------------------------------
        {
            path: '/astronauts',
            element: (
                <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                    <AppStaffList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/astronauts/add',
            element: (
                <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStaff pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/astronauts/:staffID/edit',
            element: (
                <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStaff pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/astronauts/:staffID',
            element: (
                <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Client', 'Staff']}>
                    <AppstabsProfile title="Staff Details" pageBehaviour="view" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/astronauts/:staffID',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/staff-details',
                    element: (
                        <RoleGuard permissionID="Profile-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/edit-details',
                    element: (
                        <RoleGuard permissionID="Detail-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateStaffDetails pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/attendance',
                    element: (
                        <RoleGuard permissionID="Attendance-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAttendance pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/leave',
                    element: (
                        <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffLeave pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/apply-leave',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyLeave pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/account-details',
                    element: (
                        <RoleGuard permissionID="Account-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAccountDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                // ------------------------------------------------------------------------------------------
                {
                    path: '/astronauts/:staffID/follow-up',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppAstronautsComments pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/follow-up/add',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateAstronautsComments pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/follow-up/:followUpAstronautsID/edit',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateAstronautsComments pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/follow-up/:followUpAstronautsID',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppAstronautsFolowupDetail pageBehaviour="View" />
                        </RoleGuard>
                    ),
                    children: [
                        {
                            path: '/astronauts/:staffID/follow-up/:followUpAstronautsID',
                            element: (
                                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                                    <AppAstronautsFolowupProfile pageBehaviour="View" />
                                </RoleGuard>
                            )
                        }
                    ]
                },

                // ------------------------------------------------------------------------------------------
                // {
                //     path: '/staff/:staffID/apply-account-details',
                //     element: (
                //         <RoleGuard permissionID="Account-write" userPermission={['Admin', 'Staff']}>
                //             <AppStaffApplyAccountDetails pageBehaviour="Add" />
                //         </RoleGuard>
                //     )
                // },
                // {
                //     path: '/staff/:staffID/edit-account-details',
                //     element: (
                //         <RoleGuard permissionID="Account-write" userPermission={['Admin', 'Staff']}>
                //             <AppStaffApplyAccountDetails pageBehaviour="Edit" />
                //         </RoleGuard>
                //     )
                // },
                {
                    path: '/astronauts/:staffID/warehouse',
                    element: (
                        <RoleGuard permissionID="Warehouse-read" userPermission={['Admin']}>
                            <AppStaffWarehouseDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/warehouse/add',
                    element: (
                        <RoleGuard permissionID="Warehouse-write" userPermission={['Admin']}>
                            <AppStaffUpdateWarehouse pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/warehouse/:userwarehouseID/edit',
                    element: (
                        <RoleGuard permissionID="Warehouse-write" userPermission={['Admin']}>
                            <AppStaffUpdateWarehouse pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/astronauts/:staffID/security',
                    element: (
                        <RoleGuard permissionID="Security-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffSecurity pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
            ]
        },
        //  ==========================================================
        {
            path: '/cartons',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppItemList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/cartons/add',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateItem pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/cartons/:itemsID/edit',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateItem pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/cartons/:itemsID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppCartonsDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/cartons/:itemsID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCartonsProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/cartons/:itemsID/assign',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCartonsAssign pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/cartons/:itemsID/history',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCartonsHistory pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/cartons/:itemsID/ink-details',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppInkDetails pageBehaviour="View" />
                        </RoleGuard>
                    )
                }
            ]
        },
        // ==========================================================
        {
            path: '/careers',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppCareersList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/careers/add',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateCareersList pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/careers/:carrerID/edit',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateCareersList pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/careers/:carrerID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppCareersDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/careers/:carrerID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCareersProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/careers/:carrerID/profile',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCareersProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/careers/:carrerID/Education',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCareersEducation pageBehaviour="View" />
                        </RoleGuard>
                    )
                }
            ]
        },

        // ========================================================
        {
            path: '/weekly-result',
            element: (
                <RoleGuard permissionID="WeeklyResult-read" userPermission={['Admin', 'Client']}>
                    <WeeklyResultList title="weekly-result" pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/weekly-result/add',
            element: (
                <RoleGuard permissionID="WeeklyResult-write" userPermission={['Admin']}>
                    <WeeklyResultForm pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/weekly-result/:WeeklyResultID/edit',
            element: (
                <RoleGuard permissionID="WeeklyResult-write" userPermission={['Admin']}>
                    <WeeklyResultForm pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        {
            path: '/me',
            element: (
                <RoleGuard permissionID="All" userPermission={['Admin', 'Staff']}>
                    <AppProfile title="My Profile" pageBehaviour="view" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/me/profile',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Staff']}>
                            <UpdateProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/attendance',
                    element: (
                        <RoleGuard permissionID="Attendance-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAttendance pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/leave',
                    element: (
                        <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffLeave pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/apply-leave',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyLeave pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/security',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffSecurity pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/hrm/attendance',
            element: (
                <RoleGuard permissionID="Attendance-read" userPermission={['Admin', 'staff']}>
                    <AppAttendanceList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves',
            element: (
                <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                    <AppLeaveList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves/add',
            element: (
                <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeave pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves/:leaveID/edit',
            element: (
                <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeave pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements',
            element: (
                <RoleGuard permissionID="Announcement-read" userPermission={['Admin', 'Staff']}>
                    <AppAnnouncementList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements/add',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateAnnouncement pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements/:announcementID/edit',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateAnnouncement pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/support-tickets',
            element: (
                <RoleGuard permissionID="SupportTicket-read" userPermission={['Admin']}>
                    <AppTickets pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/reports',
            element: (
                <RoleGuard permissionID="Reports-read" userPermission={['Admin']}>
                    <AppReports pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles',
            element: (
                <RoleGuard permissionID="Role-read" userPermission={['Admin']}>
                    <AppRoleList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/add',
            element: (
                <RoleGuard permissionID="Role-write" userPermission={['Admin']}>
                    <AppUpdateRole pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/:roleID/edit',
            element: (
                <RoleGuard permissionID="Role-write" userPermission={['Admin']}>
                    <AppUpdateRole pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/:roleID/',
            element: (
                <RoleGuard permissionID="Role-read" userPermission={['Admin']}>
                    <AppUpdateRole pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/spaceships',
            element: (
                <RoleGuard permissionID="Department-read" userPermission={['Admin']}>
                    <AppDepartmentList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/spaceships/add',
            element: (
                <RoleGuard permissionID="Department-write" userPermission={['Admin']}>
                    <AppUpdateDepartment pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/spaceships/:departmentID/edit',
            element: (
                <RoleGuard permissionID="Department-write" userPermission={['Admin']}>
                    <AppUpdateDepartment pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documents-types',
            element: (
                <RoleGuard permissionID="Document-read" userPermission={['Admin']}>
                    <AppDocumentsTypesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes/add',
            element: (
                <RoleGuard permissionID="Document-write" userPermission={['Admin']}>
                    <AppUpdateDocumentsTypes pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes/:documentsTypesID/edit',
            element: (
                <RoleGuard permissionID="Document-write" userPermission={['Admin']}>
                    <AppUpdateDocumentsTypes pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries',
            element: (
                <RoleGuard permissionID="Country-read" userPermission={['Admin']}>
                    <AppCountriesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries/add',
            element: (
                <RoleGuard permissionID="Country-write" userPermission={['Admin']}>
                    <AppUpdateCountries pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries/:countryID/edit',
            element: (
                <RoleGuard permissionID="Country-write" userPermission={['Admin']}>
                    <AppUpdateCountries pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states',
            element: (
                <RoleGuard permissionID="State-read" userPermission={['Admin']}>
                    <AppStatesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states/add',
            element: (
                <RoleGuard permissionID="State-write" userPermission={['Admin']}>
                    <AppUpdateStates pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states/:statesID/edit',
            element: (
                <RoleGuard permissionID="State-write" userPermission={['Admin']}>
                    <AppUpdateStates pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities',
            element: (
                <RoleGuard permissionID="City-read" userPermission={['Admin']}>
                    <AppCitiesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities/add',
            element: (
                <RoleGuard permissionID="City-write" userPermission={['Admin']}>
                    <AppUpdateCities pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities/:citiesID/edit',
            element: (
                <RoleGuard permissionID="City-write" userPermission={['Admin']}>
                    <AppUpdateCities pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift',
            element: (
                <RoleGuard permissionID="Shift-read" userPermission={['Admin']}>
                    <AppShiftList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift/add',
            element: (
                <RoleGuard permissionID="Shift-write" userPermission={['Admin']}>
                    <AppUpdateShift pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift/:shiftID/edit',
            element: (
                <RoleGuard permissionID="Shift-write" userPermission={['Admin']}>
                    <AppUpdateShift pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type',
            element: (
                <RoleGuard permissionID="LeaveType-read" userPermission={['Admin']}>
                    <AppLeaveType pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type/add',
            element: (
                <RoleGuard permissionID="LeaveType-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeaveType pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type/:leaveTypeID/edit',
            element: (
                <RoleGuard permissionID="LeaveType-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeaveType pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/holidays',
            element: (
                <RoleGuard permissionID="Holiday-read" userPermission={['Admin', 'Staff']}>
                    <AppHolidayList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/holidays/add',
            element: (
                <RoleGuard permissionID="Holiday-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateHoliday pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/holidays/:holidayID/edit',
            element: (
                <RoleGuard permissionID="Holiday-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateHoliday pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/status',
            element: (
                <RoleGuard permissionID="Status-read" userPermission={['Admin', 'Staff']}>
                    <AppStatusList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/status/add',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStatus pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/status/:statusID/edit',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStatus pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // ===============================

        {
            path: '/setting/front-setting',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin']}>
                    <AppUpdateFrontSetting pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/front-setting/:FrontSettingID/edit',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin']}>
                    <AppUpdateFrontSetting pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/testimonial',
            element: (
                <RoleGuard permissionID="Status-read" userPermission={['Admin']}>
                    <AppTestimonialList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/testimonial/add',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin']}>
                    <AppUpdateTestimonial pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/testimonail/:testimonialID/edit',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin']}>
                    <AppUpdateTestimonial pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/position',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppPositionList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/position/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdatePosition pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/position/:positionId/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdatePosition pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // ==========================
        {
            path: '/contact-managment/business',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppBusinessList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/business/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateBusiness pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/business/:businessID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateBusiness pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // ==========================
        {
            path: '/contact-managment/follow-up-astronaut',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin', 'Staff']}>
                    <AppFollowUpList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/follow-up-astronaut/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateFollowUp pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/follow-up-astronaut/:followUpAstronautId/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateFollowUp pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // =====================================
        // ==========================
        {
            path: '/contact-managment/follow-up-supervisor',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin', 'Staff']}>
                    <AppFollowUpSupervisorList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/follow-up-supervisor/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateFollowUpSupervisor pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/follow-up-supervisor/:followUpSupervisorId/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateFollowUpSupervisor pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // =====================================
        {
            path: '/contact-managment/progress-astronaut',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin', 'Staff']}>
                    <AppProgressAstronautsList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/progress-astronaut/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateProgressAstronauts pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/progress-astronaut/:progressAstronautesId/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateProgressAstronauts pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // =====================================
        {
            path: '/contact-managment/status-supervisor',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin', 'Staff']}>
                    <AppStatusSupervisorList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/status-supervisor/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStatusSupervisor pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/status-supervisor/:statusSupervisorId/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStatusSupervisor pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        {
            path: '/contact-managment/channel',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppChannelList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/channel/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateChannel pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/contact-managment/channel/:channelID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateChannel pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/style',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppStyleList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/style/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdatestyle pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/style/:styleID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdatestyle pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/endurance',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppEnduranceList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/endurance/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateEndurance pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/endurance/:enduranceID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateEndurance pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/glued',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppGluedList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/glued/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateGlued pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/glued/:gluedID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateGlued pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/record',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppRecordList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/record/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateRecord pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/record/:recordID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateRecord pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/item-types',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppInktypeList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/item-types/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateInktype pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/item-types/:itemtypeID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateInktype pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/strapling-direction',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppStraplingList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/strapling-direction/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateStrapling pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/strapling-direction/:straplingID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateStrapling pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================
        {
            path: '/items-managment/boxes-bound',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppBoxesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/boxes-bound/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateBoxes pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/boxes-bound/:boxesID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateBoxes pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // =====================================

        {
            path: '/items-managment/inks',
            element: (
                <RoleGuard permissionID="position-read" userPermission={['Admin']}>
                    <AppInksList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/inks/add',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateInks pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/items-managment/inks/:inksID/edit',
            element: (
                <RoleGuard permissionID="position-write" userPermission={['Admin']}>
                    <AppUpdateInks pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // =====================================

        {
            path: '*',
            element: <Error />
        },
        {
            path: '/profile',
            element: (
                <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'Staff']}>
                    <AppProfile pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/profile',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'Staff']}>
                            <UpdateProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/update-profile',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'Staff']}>
                            <UpdateProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/attendance',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppStaffAttendance pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/leave',
                    element: (
                        <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffLeave pageBehaviour="view" />
                        </RoleGuard>
                    )
                },

                {
                    path: '/profile/staff-details',
                    element: (
                        <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/edit-details',
                    element: (
                        <RoleGuard permissionID="Detail-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateStaffDetails pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                // {
                //     path: '/profile/staff-details',
                //     element: (
                //         <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                //             <AppStaffProfile pageBehaviour="view" />
                //         </RoleGuard>
                //     )
                // },
                // {
                //     path: '/profile/apply-leave',
                //     element: (
                //         <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                //             <AppStaffApplyLeave pageBehaviour="Add" />
                //         </RoleGuard>
                //     )
                // },
                {
                    path: '/profile/:staffID/apply-leave',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyLeave pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/update-security',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'Staff']}>
                            <UpdateSecurity pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/connection-info',
                    element: (
                        <RoleGuard permissionID="ConnectionInfo-write" userPermission={['Client', 'Staff']}>
                            <AppClientConnectionEdit pageBehaviour="Edit" clientComponent="true" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/connection-info/add',
                    element: (
                        <RoleGuard permissionID="ConnectionInfo-write" userPermission={['Client', 'Staff']}>
                            <AppClientConnectionEdit pageBehaviour="Add" clientComponent="true" />
                        </RoleGuard>
                    )
                }
            ]
        }
    ]
};

export default MainRoutes;
